@if (isLoading) {
<div class="splash-screen">
  <div class="splash-screen-inner">
    <div class="splash-loader">
      <div class="splash-logo">
        <img
          alt="Loader"
          width="96"
          height="96"
          src="./assets/images/loader.png"
        />
      </div>
    </div>
  </div>
</div>
}
